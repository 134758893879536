import { render, staticRenderFns } from "./index.vue?vue&type=template&id=217649c8"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=217649c8&prod&module=true&lang=css"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {WidgetKeyValue: require('/app/components/Widget/WidgetKeyValue.vue').default,WidgetLayoutRowCellDouble: require('/app/components/Widget/WidgetLayoutRowCellDouble.vue').default,WidgetLayoutRowSpacer: require('/app/components/Widget/WidgetLayoutRowSpacer.vue').default,ChartBar: require('/app/components/Chart/ChartBar.vue').default,MaximizableChart: require('/app/components/Chart/MaximizableChart.vue').default,CompositionSpacer: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/Composition/CompositionSpacer.vue').default,Copy: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/Copy/Copy.vue').default,SimpleTableCell: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/SimpleTable/SimpleTableCell.vue').default,SimpleTableRow: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/SimpleTable/SimpleTableRow.vue').default,Label: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/Label/Label.vue').default,SimpleTable: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/SimpleTable/SimpleTable.vue').default,WidgetBlank: require('/app/components/Widget/WidgetBlank.vue').default,ChartPie: require('/app/components/Chart/ChartPie.vue').default,WidgetLayout: require('/app/components/Widget/WidgetLayout.vue').default,CompositionLayoutSingle: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/Composition/CompositionLayoutSingle.vue').default,Composition: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/Composition/Composition.vue').default})
