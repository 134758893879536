
import moment from "moment";

import PortfolioIdleTenancy from "~/graphql/Portfolio/PortfolioIdleTenancy.gql";
import PortfolioPlannedRent from "~/graphql/Portfolio/PortfolioPlannedRent.gql";
import PortfolioTenancyLease from "~/graphql/Portfolio/PortfolioTenancyLease.gql";
import PortfolioRerentalUnits from "~/graphql/Portfolio/PortfolioRerentalUnits.gql";
import PortfolioTenancyDistribution from "~/graphql/Portfolio/PortfolioTenancyDistribution.gql";
import PortfolioRawBalanceAggregation from "~/graphql/Portfolio/PortfolioRawBalanceAggregation.gql";
import PortfolioIncomeStatementAggregation from "~/graphql/Portfolio/PortfolioIncomeStatementAggregation.gql";

import GraphMenu from "../../components/GraphMenu/GraphMenu.vue";
import TableCellWarning from "../../components/TableCellWarning/TableCellWarning.vue";

import { SALES_STATUS } from "../../../../helpers/portfolio/portfolio-sales-helpers";
import { getPortfolioColors } from "~/helpers/portfolio/portfolio-helpers.js";
import { getCurrentTenant } from "~/helpers/portfolio/portfolio-tenancy-helpers";
import { GraphInputBuilder, InputBuilder } from "~/helpers/apollo/apollo-portfolio-helpers.js";
import {
  getPropertyArea,
  getTenancyTypeDistributionPrSquareMeter,
  getRentRegulationDistributionPrSquareMeter,
  convertArea,
} from "~/helpers/portfolio/portfolio-property-helpers";
import {
  PropertyDecomissionedFilter,
  TenancyDecomissionedFilter,
  TenancyForSaleFilter,
  TenancySoldFilter,
  PropertySubTypeFilter,
  excludePropertyFromResultsFilter,
  excludeTenancyFromResultsFilter,
} from "~/helpers/apollo/apollo-portfolio-filter-helpers";
import {
  formatRentRate,
  formatEvictionRate,
  formatIdleTenancyRateRent,
  formatRentAndIdleTenancyRateRentCombined,
} from "~/helpers/portfolio/portfolio-graph-helpers.js";
import { filter } from "~/composables/useFilter";

const preferenceNameSpacePortfolio = "preferenceNamespacePortfolioPortfolio";

export default {
  components: {
    GraphMenu,
    TableCellWarning,
  },

  props: {
    portfolioId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      churnMenuOpen: false,
      churnFilterDateFrom: undefined,
      churnFilterDateTo: undefined,
      churnFilterPeriodType: undefined,

      idleTenancyPercentMenuOpen: false,
      idleTenancyPercentFilterDateFrom: undefined,
      idleTenancyPercentFilterDateTo: undefined,
      idleTenancyPercentFilterPeriodType: undefined,

      rentMenuOpen: false,
      rentFilterDateFrom: undefined,
      rentFilterDateTo: undefined,
      rentFilterPeriodType: undefined,

      colors: getPortfolioColors(),
      numberOfReRentalUnitsToShow: 8,
    };
  },

  apollo: {
    idleTenancyPercent: {
      query: PortfolioIdleTenancy,
      variables: function () {
        return new GraphInputBuilder()
          .setPortfolioId(this.portfolioId)
          .setDateFrom(this.idleTenancyPercentFilterDateFrom)
          .setDateTo(this.idleTenancyPercentFilterDateTo)
          .setPeriodType(this.idleTenancyPercentFilterPeriodType)
          .build();
      },
      update(data) {
        return data.idleTenancyMetricsForPortfolio;
      },
      skip: function () {
        return !this.idleTenancyPercentFilterDateFrom || !this.idleTenancyPercentFilterDateTo || !this.idleTenancyPercentFilterPeriodType;
      },
    },

    churn: {
      query: PortfolioTenancyLease,
      variables: function () {
        return new GraphInputBuilder()
          .setPortfolioId(this.portfolioId)
          .setDateFrom(this.churnFilterDateFrom)
          .setDateTo(this.churnFilterDateTo)
          .setPeriodType(this.churnFilterPeriodType)
          .build();
      },
      update(data) {
        return data.tenancyLeaseOverviewForPortfolio;
      },
      skip: function () {
        return !this.churnFilterDateFrom || !this.churnFilterDateTo || !this.churnFilterPeriodType;
      },
    },

    rent: {
      query: PortfolioPlannedRent,
      variables: function () {
        return new GraphInputBuilder()
          .setPortfolioId(this.portfolioId)
          .setDateFrom(this.rentFilterDateFrom)
          .setDateTo(this.rentFilterDateTo)
          .setPeriodType(this.rentFilterPeriodType)
          .setCategory("RENT")
          .build();
      },
      update(data) {
        return data.plannedRentsMetricsForPortfolio;
      },
      skip: function () {
        return !this.rentFilterDateFrom || !this.rentFilterDateTo || !this.rentFilterPeriodType;
      },
    },

    idleTenancyAmount: {
      query: PortfolioIdleTenancy,
      variables: function () {
        return new GraphInputBuilder()
          .setPortfolioId(this.portfolioId)
          .setDateFrom(this.rentFilterDateFrom)
          .setDateTo(this.rentFilterDateTo)
          .setPeriodType(this.rentFilterPeriodType)
          .setProjectAnnualRent(true)
          .build();
      },
      update(data) {
        return data.idleTenancyMetricsForPortfolio;
      },
      skip: function () {
        return !this.rentFilterDateFrom || !this.rentFilterDateTo || !this.rentFilterPeriodType;
      },
    },

    reRentalUnits: {
      query: PortfolioRerentalUnits,
      variables() {
        return new InputBuilder()
          .setId(this.portfolioId)
          .setFilter(new TenancyForSaleFilter(), "tenancyRerental")
          .setFilter(new TenancyDecomissionedFilter(), "tenancyRerental")
          .setFilter({ matches: [{ ready_for_rental: true }] }, "tenancyRerental")
          .setInput("rentCategory", "RENT")
          .setSort("rent_per_annum", "DESC", "planned_rent")
          .build();
      },
      update(data) {
        return data.assetManagementPortfolio;
      },
    },

    portfolio: {
      query: PortfolioTenancyDistribution,
      variables() {
        return new InputBuilder()
          .setId(this.portfolioId)
          .setFilter(new PropertyDecomissionedFilter(), "property")
          .setFilter(PropertySubTypeFilter(), "property")
          .setFilter(excludePropertyFromResultsFilter())
          .setFilter(new TenancyDecomissionedFilter(), "tenancy")
          .setFilter(new TenancySoldFilter(), "tenancy")
          .setFilter(excludeTenancyFromResultsFilter(), "tenancy")
          .setInput("rentCategory", "RENT")
          .setInput("periodType", "YEARLY")
          .build();
      },
      update(data) {
        return data.assetManagementPortfolio;
      },
    },

    valuation: {
      query: PortfolioRawBalanceAggregation,
      variables() {
        return {
          input: {
            categoryType: "property_value",
            periodType: "MONTHLY",
            portfolioId: this.portfolioId,
          },
        };
      },
      update(data) {
        return data.aggregatedRawBalanceValueForPortfolioByCategoryType.find(() => true); // There's only ever one item in this array
      },
    },

    capex: {
      query: PortfolioRawBalanceAggregation,
      variables() {
        return {
          input: {
            categoryType: "capex",
            periodType: "YEARLY",
            portfolioId: this.portfolioId,
          },
        };
      },
      update(data) {
        return data.aggregatedRawBalanceValueForPortfolioByCategoryType.find(() => true); // There's only ever one item in this array
      },
    },

    opex: {
      query: PortfolioIncomeStatementAggregation,
      variables() {
        return {
          input: {
            categoryType: "opex",
            periodType: "YEARLY",
            portfolioId: this.portfolioId,
            from: this.latestFullYear.startOfLastYear,
            to: this.latestFullYear.endOfLastYear,
          },
        };
      },
      update(data) {
        return data.aggregatedIncomeStatementsValueForPortfolioByCategoryType.find(() => true); // There's only ever one item in this array
      },
    },
  },

  computed: {
    filter() {
      return filter;
    },

    areaUnitLocal() {
      return this.totalArea?.areaUnit ? this.$t(`UNITS_${this.totalArea.areaUnit.toUpperCase()}`) : undefined;
    },

    idleTenancyPercentLoading() {
      return this.$apollo.queries.idleTenancyPercent.loading;
    },

    churnLoading() {
      return this.$apollo.queries.churn.loading;
    },

    rentLoading() {
      return this.$apollo.queries.rent.loading;
    },

    idleTenancyAmountLoading() {
      return this.$apollo.queries.idleTenancyAmount.loading;
    },

    portfolioLoading() {
      return this.$apollo.queries.portfolio.loading;
    },

    reRentalUnitsLoading() {
      return this.$apollo.queries.reRentalUnits.loading;
    },

    valuationLoading() {
      return this.$apollo.queries.valuation.loading;
    },

    capexLoading() {
      return this.$apollo.queries.capex.loading;
    },

    opexLoading() {
      return this.$apollo.queries.opex.loading;
    },

    yearlyRentPrAreaLoading() {
      return this.$apollo.queries.portfolio.loading;
    },

    idleTenencyPercentRate() {
      if (!this.idleTenancyPercent) return null;

      return formatIdleTenancyRateRent(this.idleTenancyPercent, this.idleTenancyPercentFilterPeriodType, true);
    },

    churnRate() {
      if (!this.churn) return null;

      return formatEvictionRate(this.churn, this.churnFilterPeriodType);
    },

    rentRate() {
      if (!this.rent) return null;

      return formatRentRate(this.rent, this.rentFilterPeriodType);
    },

    idleTenancyAmountRate() {
      if (!this.idleTenancyAmount) return null;

      return formatIdleTenancyRateRent(this.idleTenancyAmount, this.rentFilterPeriodType);
    },

    rentRateIdleTenancyCombined() {
      if (!this.idleTenancyAmount || !this.rent) return null;

      return formatRentAndIdleTenancyRateRentCombined(this.rent, this.idleTenancyAmount, this.rentFilterPeriodType);
    },

    rentRateIdleTenancyCurrency() {
      if (!this.rentRateIdleTenancyCombined) return null;

      return this.rent.find((item) => item.currency)?.currency;
    },

    reRentalUnitsForTable() {
      if (!this.reRentalUnits) return [];

      const rereantals = this.reRentalUnits.assetManagementProperties.items.flatMap((property) => property.assetManagementTenancies.items);

      // Sort by rent
      return [...rereantals]
        .sort((a, b) => {
          if (a.latestPlannedRentMetric?.amount == null && b.latestPlannedRentMetric?.amount != null) return 1;

          if (a.latestPlannedRentMetric?.amount != null && b.latestPlannedRentMetric?.amount == null) return -1;

          return b.latestPlannedRentMetric?.amount - a.latestPlannedRentMetric?.amount;
        })
        .slice(0, this.numberOfReRentalUnitsToShow);
    },

    totalNumberOfRerentalUnits() {
      if (!this.reRentalUnits) return 0;

      return this.reRentalUnits.assetManagementProperties.items.reduce((acc, property) => acc + property.assetManagementTenancies.metadata.totalCount, 0);
    },

    remainingNumberOfRerentalUnits() {
      return Math.max(this.totalNumberOfRerentalUnits - this.numberOfReRentalUnitsToShow, 0);
    },

    totalNumberOfTenancies() {
      if (!this.portfolio) return 0;

      return this.portfolio.assetManagementProperties.items.reduce((acc, property) => acc + property.assetManagementTenancies.metadata.totalCount, 0);
    },

    totalArea() {
      if (!this.portfolio) return null;

      let areaUnit;

      const area = this.portfolio.assetManagementProperties.items.reduce((acc, property) => {
        const { area, areaUnit: unit } = getPropertyArea(property);

        areaUnit ??= unit;

        return acc + area;
      }, 0);

      return { area, areaUnit };
    },

    regulationDistributionByArea() {
      if (!this.portfolio) return null;

      const propertyAreaDistributions = this.portfolio.assetManagementProperties.items.map((property) => getRentRegulationDistributionPrSquareMeter(property));

      let areaUnit = undefined;

      const portfolioDistributions = propertyAreaDistributions.reduce((acc, { distribution, areaUnit: distAreaUnit }) => {
        Object.keys(distribution).forEach((key) => {
          areaUnit ??= distAreaUnit;

          if (distribution[key] == 0) return;

          if (!acc[key]) acc[key] = 0;

          acc[key] += convertArea(distribution[key], distAreaUnit, areaUnit);
        });

        return acc;
      }, {});

      return {
        labels: Object.keys(portfolioDistributions),
        series: Object.values(portfolioDistributions),
        areaUnit,
      };
    },

    typeDistributionByArea() {
      if (!this.portfolio) return null;

      const propertyAreaDistributions = this.portfolio.assetManagementProperties.items.map((property) => getTenancyTypeDistributionPrSquareMeter(property));

      let areaUnit = undefined;

      const portfolioDistributions = propertyAreaDistributions.reduce((acc, { distribution, areaUnit: distAreaUnit }) => {
        Object.keys(distribution).forEach((key) => {
          areaUnit ??= distAreaUnit;

          if (distribution[key] == 0) return;

          if (!acc[key]) acc[key] = 0;

          acc[key] += convertArea(distribution[key], distAreaUnit, areaUnit);
        });

        return acc;
      }, {});

      return {
        labels: Object.keys(portfolioDistributions),
        series: Object.values(portfolioDistributions),
        areaUnit,
      };
    },

    currentMonthAndYear() {
      const monthAndYear = moment().format("MMMM YYYY");

      return monthAndYear.charAt(0).toUpperCase() + monthAndYear.slice(1); // Capitalize first letter
    },

    startOfYear() {
      const monthAndYear = moment().startOf("year").format("MMMM YYYY");

      return monthAndYear.charAt(0).toUpperCase() + monthAndYear.slice(1); // Capitalize first letter
    },

    latestFullYear() {
      const startOfLastYear = moment.utc().startOf("year").subtract(1, "year");
      const endOfLastYear = moment.utc().endOf("year").subtract(1, "year");

      return { startOfLastYear, endOfLastYear };
    },

    lastYear() {
      return moment().subtract(1, "year").format("YYYY");
    },

    yearlyRentPrKvm() {
      if (!this.portfolio || !this.totalArea) return "–";

      const totalRent = this.portfolio.latestPlannedRentMetric?.amount;
      const currency = this.portfolio.latestPlannedRentMetric?.currency;
      const value = totalRent / this.totalArea.area;

      return this.filter.currency(value, { currency });
    },

    portfolioValuation() {
      return this.valuation ?? {};
    },

    portfolioValuationTotal() {
      const value = this.portfolioValuation.totalCostWithVat;
      const currency = this.portfolioValuation.currency;

      return this.filter.currency(value, { currency, mode: "compact" });
    },

    portfolioValuationChange() {
      if (!this.portfolioValuation) return null;

      return this.getChangeDescription(this.portfolioValuation.changeTrend, this.portfolioValuation.changePercentage);
    },

    portfolioCapex() {
      return this.capex ?? {};
    },

    portfolioCapexChange() {
      if (!this.portfolioCapex) return null;

      return this.getChangeDescription(this.portfolioCapex.changeTrend, this.portfolioCapex.changePercentage);
    },

    portfolioCapexTotal() {
      const value = this.portfolioCapex.totalCostWithVat;
      const currency = this.portfolioCapex.currency;

      return this.filter.currency(value, { currency });
    },

    portfolioOpex() {
      return this.opex ?? {};
    },

    portfolioOpexByArea() {
      if (!this.totalArea) return "–";

      const opex = this.portfolioOpex?.totalCostWithVat ?? 0;
      const currency = this.portfolioOpex?.currency;
      const area = this.totalArea.area;
      const value = Math.abs(opex / area);

      if (!currency) return "–";

      return filter.currency(value, { currency });
    },

    portfolioOpexByAreaChange() {
      if (!this.portfolioOpex) return null;

      return this.getChangeDescription(this.portfolioOpex.changeTrend, this.portfolioOpex.changePercentage, false);
    },
  },

  watch: {
    churnFilterDateTo(val) {
      this.$userPreferences.setKey(preferenceNameSpacePortfolio, "churnFilterDateTo", val);
    },

    churnFilterDateFrom(val) {
      this.$userPreferences.setKey(preferenceNameSpacePortfolio, "churnFilterDateFrom", val);
    },

    churnFilterPeriodType(val) {
      this.$userPreferences.setKey(preferenceNameSpacePortfolio, "churnFilterPeriodType", val);
    },

    idleTenancyPercentFilterDateTo(val) {
      this.$userPreferences.setKey(preferenceNameSpacePortfolio, "idleTenancyPercentFilterDateTo", val);
    },

    idleTenancyPercentFilterDateFrom(val) {
      this.$userPreferences.setKey(preferenceNameSpacePortfolio, "idleTenancyPercentFilterDateFrom", val);
    },

    idleTenancyPercentFilterPeriodType(val) {
      this.$userPreferences.setKey(preferenceNameSpacePortfolio, "idleTenancyPercentFilterPeriodType", val);
    },

    rentFilterDateTo(val) {
      this.$userPreferences.setKey(preferenceNameSpacePortfolio, "rentFilterDateTo", val);
    },

    rentFilterDateFrom(val) {
      this.$userPreferences.setKey(preferenceNameSpacePortfolio, "rentFilterDateFrom", val);
    },

    rentFilterPeriodType(val) {
      this.$userPreferences.setKey(preferenceNameSpacePortfolio, "rentFilterPeriodType", val);
    },
  },

  async mounted() {
    // Fetch user preferences
    this.churnFilterDateTo = await this.$userPreferences.getKey(preferenceNameSpacePortfolio, "churnFilterDateTo");
    this.churnFilterDateFrom = await this.$userPreferences.getKey(preferenceNameSpacePortfolio, "churnFilterDateFrom");
    this.churnFilterPeriodType = await this.$userPreferences.getKey(preferenceNameSpacePortfolio, "churnFilterPeriodType");

    this.idleTenancyPercentFilterDateTo = await this.$userPreferences.getKey(preferenceNameSpacePortfolio, "idleTenancyPercentFilterDateTo");
    this.idleTenancyPercentFilterDateFrom = await this.$userPreferences.getKey(preferenceNameSpacePortfolio, "idleTenancyPercentFilterDateFrom");
    this.idleTenancyPercentFilterPeriodType = await this.$userPreferences.getKey(preferenceNameSpacePortfolio, "idleTenancyPercentFilterPeriodType");

    this.rentFilterDateTo = await this.$userPreferences.getKey(preferenceNameSpacePortfolio, "rentFilterDateTo");
    this.rentFilterDateFrom = await this.$userPreferences.getKey(preferenceNameSpacePortfolio, "rentFilterDateFrom");
    this.rentFilterPeriodType = await this.$userPreferences.getKey(preferenceNameSpacePortfolio, "rentFilterPeriodType");
  },

  methods: {
    logAmplitudeEvent(hierarchy, page) {
      const properties = {
        hierarchy: hierarchy,
        page: page,
      };

      this.$amplitude.navigateToTab(properties);
    },

    toggleChurnMenu() {
      this.churnMenuOpen = !this.churnMenuOpen;
    },

    updateChurnFilter(event) {
      this.churnFilterDateFrom = event.dateFrom;
      this.churnFilterDateTo = event.dateTo;
      this.churnFilterPeriodType = event.periodType;
    },

    updateIdlePercentTenancyFilter(event) {
      this.idleTenancyPercentFilterDateFrom = event.dateFrom;
      this.idleTenancyPercentFilterDateTo = event.dateTo;
      this.idleTenancyPercentFilterPeriodType = event.periodType;
    },

    toggleIdleTenancyPercentMenu() {
      this.idleTenancyPercentMenuOpen = !this.idleTenancyPercentMenuOpen;
    },

    toggleRentMenu() {
      this.rentMenuOpen = !this.rentMenuOpen;
    },

    rentFilter(event) {
      this.rentFilterDateFrom = event.dateFrom;
      this.rentFilterDateTo = event.dateTo;
      this.rentFilterPeriodType = event.periodType;
    },

    getCurrentRent(tenancy) {
      if (!tenancy) return 0;

      const value = tenancy.latestPlannedRentMetric?.amount;
      const currency = tenancy.latestPlannedRentMetric?.currency;

      return this.filter.currency(value, { currency });
    },

    hasChurn(tenancy) {
      if (!tenancy) return false;

      const currentTenant = getCurrentTenant(tenancy);

      return currentTenant?.isGhost ?? false;
    },

    navigateToTenancy(tenancy, subPage = "overview") {
      if (!tenancy) return null;
      this.logAmplitudeEvent("tenancy", subPage);

      this.$router.push({
        name: `portfolio-tenancy-tenancyId-${subPage}`,
        params: {
          tenancyId: tenancy.id,
        },
      });
    },

    navigateToBalance() {
      this.logAmplitudeEvent("portfolio", "balance");

      this.$router.push({
        name: "portfolio-portfolioId-balance",
        params: {
          portfolioId: this.portfolioId,
        },
      });
    },

    navigateToOpex() {
      this.logAmplitudeEvent("portfolio", "opex");

      this.$router.push({
        name: "portfolio-portfolioId-opex",
        params: {
          portfolioId: this.portfolioId,
        },
      });
    },

    navigateToRerentList() {
      this.logAmplitudeEvent("portfolio", "tenancies");

      this.$router.push({
        name: "portfolio-portfolioId-tenancies",
        query: {
          rentStatus: "rerent",
          salesStatus: `${SALES_STATUS.NOT_FOR_SALE},${SALES_STATUS.PREPARING_FOR_SALE}`,
          sort: JSON.stringify({
            key: "rent_per_annum",
            relation: "planned_rent",
            direction: "DESC",
          }),
        },
      });
    },

    getChangeDescription(changeTrend, changePercentage, upwardTrendPositive = true) {
      if (!changePercentage || changePercentage < 0.1) {
        return {
          color: "--color-black-900",
          text: this.$t("PORTFOLIO_OVERVIEW_CHANGE_TREND_NO_CHANGE"),
        };
      } else if (changeTrend == "DOWNWARDS") {
        return {
          colorNamespace: upwardTrendPositive ? "pink" : "green",
          icon: "arrow_downward",
          text: this.$t("PORTFOLIO_OVERVIEW_CHANGE_TREND_DOWN", { percent: `${changePercentage.toFixed(1)}%` }),
        };
      } else {
        return {
          colorNamespace: upwardTrendPositive ? "green" : "pink",
          icon: "arrow_upward",
          text: this.$t("PORTFOLIO_OVERVIEW_CHANGE_TREND_UP", { percent: `${changePercentage.toFixed(1)}%` }),
        };
      }
    },

    filterArea(value, unit) {
      return this.filter.area(value, unit);
    },
  },
};
